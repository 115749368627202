<template>
  <div class="general-complaint-list">
    <pageHeaderView></pageHeaderView>
    <Card class="content">
      <div class="card-header">
        <div class="card-header-text">欠薪线索反映</div>
        <div class="card-header-divider"></div>
      </div>
      <div class="box-header">
        <div class="box-header-left">
          <city-select-list style="display:inline-block" :city="region" @selectCity="selectCity"></city-select-list>
          <div class="status-filter">
            <Select v-model="status">
              <Option :value="-1">状态：不限</Option>
              <Option :value="0">待处理</Option>
              <Option :value="1">办理中</Option>
              <Option :value="2">已办结</Option>
              <Option :value="3">已关闭</Option>
            </Select>
          </div>
          <div class="field-filter">
            <Select v-model="field">
              <Option :value="-1">领域：不限</Option>
              <Option :value="0">非工程建设领域</Option>
              <Option :value="1">工程建设领域</Option>
            </Select>
          </div>
        </div>
        <div class="box-tools">
          <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
            <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
              <Option value="">不限</Option>
              <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
            </Select>&nbsp;
            <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
            <Button type="default" icon="ios-search" @click="search"></Button>
          </div>
          <Button-group>
            <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
          </Button-group>
        </div>
      </div>
      <div class="box-body">
        <Table stripe :columns="columns" :data="data" ref="table" @on-selection-change="selectRowChange"></Table>
        <div style="margin: 10px;overflow: hidden">
          <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
          <div style="float: right;">
            <Page :total="totalCount" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
          </div>
        </div>
        <Modal v-model="modalDetail" @on-cancel="closeDetailModal" width="720">
          <p slot="header">
            <Icon type="information-circled"></Icon>
            <span>查看详情</span>
          </p>
          <div class="detail-view" v-if="dataSelected&&dataSelected.ComplainantName">
            <table class="detail-table">
              <tr>
                <th width="200">欠薪地区</th>
                <td>{{ dataSelected.RegionFullName }}</td>
              </tr>
              <tr>
                <th width="200">类型</th>
                <td>{{ dataSelected.Field ? '工程建设领域' : '非工程建设领域' }}</td>
              </tr>
              <tr>
                <th width="200">状态</th>
                <td>
                  <Tag :color="$common.generalComplaintStatusColor[dataSelected.Status.toString()]">{{ $common.generalComplaintStatus[dataSelected.Status.toString()] }}</Tag>
                </td>
              </tr>
              <tr>
                <th width="200">反映人姓名</th>
                <td>{{ dataSelected.ComplainantName }}</td>
              </tr>
              <tr>
                <th width="200">反映人身份证号</th>
                <td>{{ dataSelected.ComplainantIDNumber }}</td>
              </tr>
              <tr>
                <th width="200">反映人联系电话</th>
                <td>{{ dataSelected.ComplainantMobile }}</td>
              </tr>
              <tr>
                <th width="200">拖欠工资项目名称</th>
                <td>{{ dataSelected.ProjectName }}</td>
              </tr>
              <tr>
                <th width="200">欠薪单位名称</th>
                <td>{{ dataSelected.CompanyName }}</td>
              </tr>
              <tr>
                <th width="200">欠薪负责人名称</th>
                <td>{{ dataSelected.PrincipalName }}</td>
              </tr>
              <tr>
                <th width="200">欠薪负责人电话</th>
                <td>{{ dataSelected.PrincipalTel }}</td>
              </tr>
              <tr>
                <th width="200">欠薪人数</th>
                <td>{{ dataSelected.ComplainantPersonCount }}</td>
              </tr>
              <tr>
                <th width="200">欠薪金额</th>
                <td>{{ Math.round(dataSelected.Amount / 10000) / 100 }}万元</td>
              </tr>
              <tr>
                <th width="200">欠薪时间</th>
                <td>{{ $moment(dataSelected.OccurDate).format('YYYY-MM-DD') }}</td>
              </tr>
              <tr>
                <th width="200">欠薪基本情况</th>
                <td><div class="pre">{{ dataSelected.Content }}</div></td>
              </tr>
              <tr>
                <th width="200">上报时间</th>
                <td>{{ $moment(dataSelected.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
              <tr>
                <th width="200">欠薪基本情况</th>
                <td><div class="pre">{{ dataSelected.Content }}</div></td>
              </tr>
              <tr v-if="dataSelected.Status===2">
                <th width="200">处理结果</th>
                <td><div class="pre">{{ dataSelected.Result }}</div></td>
              </tr>
              <tr v-if="dataSelected.Status===2">
                <th width="200">办结时间</th>
                <td>{{ $moment(dataSelected.DoneTime).format('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
              <tr v-if="dataSelected.Status===3">
                <th width="200">关闭时间</th>
                <td>{{ $moment(dataSelected.DoneTime).format('YYYY-MM-DD HH:mm:ss') }}</td>
              </tr>
            </table>
          </div>
        </Modal>
        <Modal v-model="modalDoing" width="360">
          <p slot="header">
            <Icon type="information-circled"></Icon>
            <span>确认办理</span>
          </p>
          <div style="text-align:center">
            <p>是否确认办理此投诉？</p>
          </div>
          <div slot="footer">
            <Button type="primary" size="large" long :loading="modal_loading" @click="doing">办理</Button>
          </div>
        </Modal>
        <Modal v-model="modalDone" width="640">
          <p slot="header">
            <Icon type="information-circled"></Icon>
            <span>处理结果</span>
          </p>
          <div>
            <Form ref="doneForm" :model="modalDoneModel" :rules="ruleDoneValidate" :label-width="110">
              <Form-item label="处理结果" prop="Result">
                <Input type="textarea" v-model="modalDoneModel.Result" :autosize="{minRows: 10}" placeholder="请输入处理结果"></Input>
              </Form-item>
            </Form>
          </div>
          <div slot="footer">
            <Button type="warning" size="large" long :loading="modal_loading" @click="modalDoneConfirm">确认办结</Button>
          </div>
        </Modal>
        <Modal v-model="modalDoneConfirmed" width="360">
          <p slot="header">
            <Icon type="information-circled"></Icon>
            <span>确认办结</span>
          </p>
          <div style="text-align:center">
            <p>是否确认此投诉已办结？</p>
          </div>
          <div slot="footer">
            <Button type="warning" size="large" long :loading="modal_loading" @click="done">确认办结</Button>
          </div>
        </Modal>
        <Modal v-model="modalClose" width="360">
          <p slot="header">
            <Icon type="information-circled"></Icon>
            <span>确认关闭</span>
          </p>
          <div style="text-align:center">
            <p>是否确认关闭此投诉？</p>
          </div>
          <div slot="footer">
            <Button type="error" size="large" long :loading="modal_loading" @click="close">办理</Button>
          </div>
        </Modal>
      </div>
    </Card>
  </div>
</template>

<script>
import pageHeaderView from '../public/PageHeader'
import citySelectList from '../public/CitySelectList'
export default {
  name: 'general-complaint-list',
  components: {
    pageHeaderView,
    citySelectList
  },
  data () {
    return {
      datatableData: [],
      loading: false,
      dataSelected: null,
      modal_loading: false,
      modalDelete: false,
      modalDetail: false,
      modalDoing: false,
      modalDoneConfirmed: false,
      modalDoneModel: {
        Tid: null,
        Result: null
      },
      ruleDoneValidate: {
        Result: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
      },
      modalDone: false,
      modalClose: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 60, align: 'center' },
        {
          title: '欠薪地区',
          key: 'RegionFullName',
          width: 240,
          ellipsis: true
        },
        {
          title: '类型',
          key: 'Field',
          width: 120,
          render: (h, params) => {
            return h('span', {}, params.row.Field ? '工程建设领域' : '非工程建设领域')
          }
        },
        {
          title: '状态',
          key: 'Status',
          width: 120,
          render: (h, params) => {
            return h('Tag', {
              props: {
                // type: 'dot',
                color: this.$common.generalComplaintStatusColor[params.row.Status.toString()]
              }
            }, this.$common.generalComplaintStatus[params.row.Status.toString()])
          }
        },
        {
          title: '反映人',
          key: 'ComplainantName',
          width: 120,
          ellipsis: true,
          searchable: true
        },
        {
          title: '反映人电话',
          key: 'ComplainantMobile',
          width: 120,
          ellipsis: true,
          searchable: true,
          render: (h, params) => {
            return h('span', {}, params.row.ComplainantMobile)
          }
        },
        {
          title: '欠薪单位',
          key: 'CompanyName',
          width: 240,
          ellipsis: true,
          searchable: true
        },
        {
          title: '欠薪人数',
          key: 'ComplainantPersonCount',
          width: 120,
          render: (h, params) => {
            return h('span', {}, params.row.ComplainantPersonCount)
          }
        },
        {
          title: '欠薪金额',
          key: 'Amount',
          width: 120,
          render: (h, params) => {
            return h('span', {}, Math.round(params.row.Amount / 10000) / 100 + '万元')
          }
        },
        // {
        //   title: '欠薪基本情况',
        //   key: 'Content',
        //   render: (h, params) => {
        //     let content = this.getContent(params.row)
        //     return h('span', {
        //       class: 'font-grey'
        //     }, content)
        //   }
        // },
        {
          title: '上报时间',
          key: 'CreateTime',
          width: 160,
          render: (h, params) => {
            return h('span', null, this.$moment(params.row.CreateTime).format('YYYY-MM-DD'))
          }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 220,
          render: (h, params) => {
            return this.getActionBtns(h, params.row)
          }
        }
      ],
      data: [],
      region: null,
      province: 0,
      municipality: 0,
      county: 0,
      totalCount: 0,
      pageTotal: 0,
      status: -1,
      field: -1
    }
  },
  watch: {
    status: function () {
      this.pageCurrent = 1
      this.initDataTable()
    },
    field: function () {
      this.pageCurrent = 1
      this.initDataTable()
    }
  },
  mounted () {
    this.$store.dispatch('setPageStore', {
      pageHeader: '欠薪线索反映',
      pageDescr: '全部列表'
    })
    this.initDataTable()
  },
  methods: {
    getActionBtns (h, row) {
      let btns = [
        h('Button', {
          props: {
            type: 'default',
            size: 'small',
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.dataSelected = row
              this.modalDetail = true
            }
          }
        }, '详情')
      ]
      if (row.Status === 0) {
        btns.push(h('Button', {
          props: {
            type: 'primary',
            size: 'small',
            ghost: true
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.dataSelected = row
              this.modalDoing = true
            }
          }
        }, '办理'))
        btns.push(h('Button', {
          props: {
            type: 'warning',
            size: 'small',
            ghost: true
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.dataSelected = row
              this.modalDoneModel = {
                Tid: row.Tid,
                Result: null
              }
              this.modalDone = true
            }
          }
        }, '办结'))
        btns.push(h('Button', {
          props: {
            type: 'error',
            size: 'small',
            ghost: true
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.dataSelected = row
              this.modalClose = true
            }
          }
        }, '关闭'))
      } else if (row.Status === 1) {
        btns.push(h('Button', {
          props: {
            type: 'warning',
            size: 'small',
            ghost: true
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.dataSelected = row
              this.modalDone = true
            }
          }
        }, '办结'))
        btns.push(h('Button', {
          props: {
            type: 'error',
            size: 'small',
            ghost: true
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.dataSelected = row
              this.modalClose = true
            }
          }
        }, '关闭'))
      }
      return btns
    },
    selectCity (value) {
      console.log(value)
      this.region = value
      if (value && value.length > 0) {
        this.province = parseInt(value.substr(0, 2))
        this.municipality = parseInt(value.substr(2, 2))
        this.county = parseInt(value.substr(4, 2))
        this.initDataTable()
      } else {
        this.province = 0
        this.municipality = 0
        this.county = 0
        this.initDataTable()
      }
    },
    initDataTable (query = {}) {
      let queryModel = {
        PageIndex: this.pageCurrent,
        PageSize: this.pageSize,
        Province: this.province,
        Municipality: this.municipality,
        County: this.county,
        Status: this.status,
        Field: this.field
      }
      Object.assign(queryModel, query)
      this.$Spin.show()
      this.$Api.Project.getGeneralComplaintList(queryModel).then((respBody) => {
        this.pageTotal = respBody.PageCount
        this.totalCount = respBody.TotalCount
        this.$Spin.hide()
        this.data = respBody.List
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取列表失败!',
          desc: err.message
        })
      })
    },
    getContent (row) {
      let content = ''
      if (row.Content.length >= 60) {
        content = row.Content.substr(0, 60) + '...'
      } else {
        content = row.Content
      }
      return content
    },
    closeComplaintDetail () {
      this.modalDetail = false
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
      this.initDataTable()
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
      this.initDataTable()
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList
      })
    },
    modalDoneConfirm () {
      this.$refs['doneForm'].validate((valid) => {
        if (valid) {
          this.modalDoneConfirmed = true
        } else {
          this.$Message.error('表单验证失败!')
        }
      })
    },
    async done () {
      this.modal_loading = true
      await this.$Api.Project.setGeneralComplaintDone(this.modalDoneModel)
        .then(() => {
          this.$Message.success('操作成功')
          let item = this.data.find(o => o.Tid === this.modalDoneModel.Tid)
          item.Status = 2
          item.Result = this.modalDoneModel.Result
          item.DoneTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
          this.modalDoneModel = {
            Tid: null,
            Result: null
          }
        })
        .catch((err) => {
          this.$Message.error('操作失败！' + err.message)
        })
      this.modal_loading = false
      this.modalDone = false
      this.modalDoneConfirmed = false
    },
    async doing () {
      this.modal_loading = true
      await this.$Api.Project.setGeneralComplaintDoing(this.dataSelected.Tid)
        .then(() => {
          this.$Message.success('操作成功')
          let item = this.data.find(o => o.Tid === this.dataSelected.Tid)
          item.Status = 1
        })
        .catch((err) => {
          this.$Message.error('操作失败！' + err.message)
        })
      this.modal_loading = false
      this.modalDoing = false
    },
    async close () {
      this.modal_loading = true
      await this.$Api.Project.setGeneralComplaintClose(this.dataSelected.Tid)
        .then(() => {
          this.$Message.success('操作成功')
          let item = this.data.find(o => o.Tid === this.dataSelected.Tid)
          item.Status = 3
        })
        .catch((err) => {
          this.$Message.error('操作失败！' + err.message)
        })
      this.modal_loading = false
      this.modalClose = false
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      if (this.searchBy) {
        this.pageCurrent = 1
        let query = {}
        query[this.searchBy] = this.keyword
        this.initDataTable(query)
      }
    },
    closeDetailModal () {
      this.modalDetail = false
      this.dataSelected = null
    }
  }
}
</script>

<style lang="less" scoped>
.detail-view {
  .detail-line {
    display: block;
    .title {
      display: inline-block;
    }
    .value {
      display: inline;
    }
  }
}
.status-filter {
  display: inline-block;
  margin-left: 8px;
}
.field-filter {
  display: inline-block;
  margin-left: 8px;
}
.pre {
  white-space: pre;
}
</style>
